import React from "react";
import './App.css';
import {Route, Switch} from "react-router-dom";
import Main from "./Main";
import ShowDay from "./ShowDay";
import Prices from "./Prices";

const App = () => {

    return <>
        <Switch>
            <Route path={"/dag/:day"} exact component={ShowDay} />
            <Route path={"/premier"} exact component={Prices} />
            <Route>
                <Main/>
            </Route>
        </Switch>
    </>
}

export default App;
