export const days = [
    {
        day: 1,
        youtube: 'WeeECzQu-cw',
        upload: 'https://flogvit.smugmug.com/upload/h8Wct2/luke1',
        sponsor: {url: 'https://grensesnitt.no', price: '200 kr', name: 'Grensesnitt'},
        winner: {name: "Tove Andresen", imageUrl: "/assets/Luke-1.jpg"}
    },
    {
        day: 2,
        youtube: 'F9xKJR__8aA',
        upload: 'https://flogvit.smugmug.com/upload/5rBHKr/luke2',
        sponsor: {url: 'https://grensesnitt.no', price: '200 kr', name: 'Grensesnitt'},
        winner: {name: "Judith Andrea", imageUrl: "/assets/Luke-2.jpg"}
    },
    {
        day: 3,
        youtube: 'SvWYecblaD0',
        upload: 'https://flogvit.smugmug.com/upload/T7G8NG/luke3',
        sponsor: {url: 'https://grensesnitt.no', price: '200 kr', name: 'Grensesnitt'},
        winner: {name: "Katrine Hole", imageUrl: "/assets/Luke-3.jpg"}
    },
    {
        day: 4,
        youtube: 'OY4HkBY35TY',
        upload: 'https://flogvit.smugmug.com/upload/THvzmx/luke4',
        sponsor: {url: 'https://grensesnitt.no', price: '200 kr', name: 'Grensesnitt'},
        winner: {name: "Grete Jonsson", imageUrl: "/assets/Luke-4.jpg"}
    },
    {
        day: 5,
        youtube: 'o-nFUTYU1ck',
        upload: 'https://flogvit.smugmug.com/upload/ckDbRX/luke5',
        sponsor: {url: 'https://grensesnitt.no', price: '200 kr', name: 'Grensesnitt'},
        winner: {name: "Bjørn Ueland", imageUrl: "/assets/Luke-5.jpg"}
    },
    {
        day: 6,
        youtube: 'y2OFNgWY6LM',
        upload: 'https://flogvit.smugmug.com/upload/6nttrM/luke6',
        sponsor: {url: 'https://grensesnitt.no', price: '200 kr', name: 'Grensesnitt'},
        winner: {name: "Monica Engebretsen", imageUrl: "/assets/Luke-6.jpg"}
    },
    {
        day: 7,
        youtube: 't2Ru72uZ3zI',
        upload: 'https://flogvit.smugmug.com/upload/GV9zTB/luke7',
        sponsor: {url: 'https://grensesnitt.no', price: '200 kr', name: 'Grensesnitt'},
        winner: {name: "Judith Andrea", imageUrl: "/assets/Luke-7.jpg"}
    },
    {
        day: 8,
        youtube: '5YMU3Az28mo',
        upload: 'https://flogvit.smugmug.com/upload/h8TVVC/luke8',
        sponsor: {url: 'https://grensesnitt.no', price: '200 kr', name: 'Grensesnitt'},
        winner: {name: 'Eli Kristin Hårberg', imageUrl: '/assets/Luke-8.jpg'}
    },
    {
        day: 9,
        youtube: '9HXDRexm3mw',
        upload: 'https://flogvit.smugmug.com/upload/kf4x5K/luke9',
        sponsor: {url: 'https://grensesnitt.no', price: '200 kr', name: 'Grensesnitt'},
        winner: {name: 'Adrian Oddane', imageUrl: '/assets/Luke-9.jpg'}
    },
    {
        day: 10,
        youtube: 'WNZ3vZ-AfGs',
        upload: 'https://flogvit.smugmug.com/upload/CCFJfv/luke10',
        sponsor: {url: 'https://grensesnitt.no', price: '200 kr', name: 'Grensesnitt'},
        winner: {name: 'Synnove G. Solberg', imageUrl: '/assets/Luke-10.jpg'}
    },
    {
        day: 11,
        youtube: '3oEEq4ClyD4',
        upload: 'https://flogvit.smugmug.com/upload/X6NQxc/luke11',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: 'Marie Hanssen', imageUrl: '/assets/Luke-11.jpg'}
    },
    {
        day: 12,
        youtube: '0QHIBsVjF5Q',
        upload: 'https://flogvit.smugmug.com/upload/dmQ2pP/luke12',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: 'Marek Ziolkowski', imageUrl: '/assets/Luke-12.jpg'}
    },
    {
        day: 13,
        youtube: 'YcN4Kahrork',
        upload: 'https://flogvit.smugmug.com/upload/ZB9XN3/luke13',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: 'Victoria', imageUrl: '/assets/Luke-13.jpg'}
    },
    {
        day: 14,
        youtube: 'UkU_s2ZH8s8',
        upload: 'https://flogvit.smugmug.com/upload/2KZCBW/luke14',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: 'Kjetil', imageUrl: '/assets/Luke-14.jpg'}
    },
    {
        day: 15,
        youtube: '8C50k6Sqg4w',
        upload: 'https://flogvit.smugmug.com/upload/sLSJpK/luke15',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: 'Gaute Frøystein', imageUrl: '/assets/Luke-15.jpg'}
    },
    {
        day: 16,
        youtube: 'GksPXjhi3_M',
        upload: 'https://flogvit.smugmug.com/upload/D8XqGV/luke16',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: 'Bjørg Ueland', imageUrl: '/assets/Luke-16.jpg'}
    },
    {
        day: 17,
        youtube: 'mvzioyyxmEI',
        upload: 'https://flogvit.smugmug.com/upload/cLsWH5/luke17',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {imageUrl: '/assets/Luke-17.jpg'}
    },
    {
        day: 18,
        youtube: 'thnOaKDBLe0',
        upload: 'https://flogvit.smugmug.com/upload/9tDJRK/luke18',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: 'Monica Engebretsen', imageUrl: '/assets/Luke-18.jpg'}
    },
    {
        day: 19,
        youtube: 'ALsf6F-TSwQ',
        upload: 'https://flogvit.smugmug.com/upload/P9hXqL/luke19',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: 'Birgit Fosterfold', imageUrl: '/assets/Luke-19.jpg'}
    },
    {
        day: 20,
        youtube: 'iDRz84lWPso',
        upload: 'https://flogvit.smugmug.com/upload/rL6mc4/luke20',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: 'Adrian Oddane', imageUrl: '/assets/Luke-20.jpg'}
    },
    {
        day: 21,
        youtube: 'zhRmLMP0a3o',
        upload: 'https://flogvit.smugmug.com/upload/vKwHBB/luke21',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: 'Bjørg Ueland', imageUrl: '/assets/Luke-21.jpg'}
    },
    {
        day: 22,
        youtube: 'shII8J5d25k',
        upload: 'https://flogvit.smugmug.com/upload/4k2CS5/luke22',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {name: "Katrine Hole", imageUrl: "/assets/Luke-22.jpg"}
    },
    {
        day: 23,
        youtube: 'FCrsxtvEX7I',
        upload: 'https://flogvit.smugmug.com/upload/Dd5XhH/luke23',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {imageUrl: '/assets/Luke-23.jpg'}
    },
    {
        day: 24,
        youtube: 'hzw8IAL48FE',
        upload: 'https://flogvit.smugmug.com/upload/Sz64nh/luke24',
        sponsor: {url: 'https://menneske.no', price: '200 kr', name: 'Flogvit Data'},
        winner: {imageUrl: '/assets/Luke-24.jpg'}
    }
]
