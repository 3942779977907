const color = {
    primary: {
        base: "#6a2132",
    },
};

const font = {
    h1: {
        "font-family": "Khula, sans-serif",
        "font-style": "normal",
        "font-weight": "bold",
        "font-size": "40px",
        "line-height": 1,
    },
    h2: {
        "font-family": "Khula, sans-serif",
        "font-style": "normal",
        "font-weight": "bold",
        "font-size": "20px",
        "line-height": 1,
    },
    t: {
        "font-family": "Helvetica Neue, sans-serif",
        "font-style": "normal",
        "font-weight": "bold",
        "font-size": "14px",
        "line-height": 1,
    },
};

export const device = {
    mobile: `(min-width: 0px) and (max-width: 767px)`,
    tablet: `(min-width: 768px) and (max-width: 1223px)`,
    desktop: `(min-width: 1224px)`,
    m2t: `(min-width: 0px) and (max-width: 1223px)`,
    t2d: `(min-width: 768px)`,
};

export default {
    breakpoints: {
        m: 0,
        p: 768,
        d: 992,
    },
    spacing: {
        0: "0",
        1: "0.25rem",
        2: "0.5rem",
        3: "1rem",
        4: "1.5rem",
        5: "3rem",
    },
    font: font,
    color: color,
    text: {
        color: {
            main: color.primary.base,
            link: color.primary.base,
        },
    },
};
