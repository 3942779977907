import styled from 'styled-components';

export const DoorButton = styled.div`
  background-color: ${(props) => props.open ? "rgba(255, 255, 255, 0.8)" : "rgba(255,255,255,0.2)"};
  height: ${props => props.size};
  width: ${props => props.size};
  margin-right: ${props => props.margin};
  margin-bottom: ${props => props.margin};
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/border.svg");
  border-radius: 9px;
  cursor: ${props => props.open ? 'pointer' : 'default'};
`

export default DoorButton;
