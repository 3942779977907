import {useHistory, useParams} from "react-router-dom";
import {days} from './data';
import React, {useEffect, useMemo, useState} from "react";
import YouTube from "react-youtube";
import H1 from "./components/texts/H1";
import T from "./components/texts/T";
import {Button} from "./components/buttons/Button";
import styled from 'styled-components';
import {device} from "./theme";
import useMedia from "./components/helpers/hooks/useMedia";
import BackButton from "./components/buttons/BackButton";

const Container = styled.div`
  margin: auto;
  width: 640px;
  @media only screen and ${device.mobile} {
    width: 100%;
  }
`

const Info = styled.div`
  margin: 10px 0 10px 0;
  @media only screen and ${device.mobile} {
    margin: 10px;
  }
`

const WinnerInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const WinnerImage = styled.img`
  margin-top: 10px;
  width: 100%;
`

const YouTubePlay = ({day, width}) => {
    const {isMobile} = useMedia();

    const opts = useMemo(() => {
        return {
            height: width < 640 ? '' + Math.floor((width / 640) * 390) : '390',
            width: isMobile ? "100%" : "640px",
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
            }
        }
    }, [isMobile]);

    return <YouTube videoId={day.youtube} opts={opts}/>
}

const ShowDay = () => {
    const params = useParams();
    const dayId = Number(params.day);
    const day = days.find(d => d.day === dayId) || null;
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    const history = useHistory();

    const today = useMemo(() => {
            const date = new Date();

            const mDay = date.getDate();
            const month = date.getMonth() + 1;
            if (month === 12 && mDay <= 31) return mDay;
            history.push("/");
            return 0;
        }, []
    )


    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return <Container>
        {day && <>
            <Info>
                <H1 style={{textAlign: "center"}}>Luke {day?.day}</H1>
                <WinnerInfo>
                    <div>
                        <T><a href={day?.sponsor?.url} target={"_blank"} rel={"noreferrer"}>Premie sponset
                            av {day?.sponsor?.name}</a></T></div>
                    <div><T>Premie: {day?.sponsor?.price}</T></div>
                    {today > day?.day && <div>
                        <a href={`https://photos.flogvit.com/Adventskalender/2021/Luke-${day.day}`}
                           target={"_blank"} rel={"noreferrer"}><T>Se innsendte bilder</T></a>
                    </div>}
                    {today > day?.day && day?.winner && <>
                        <div><T>Vinner: {day.winner?.name && <>{day.winner?.name}</>}{!day.winner?.name && <>Ukjent vinner. Ta kontakt med Vegard.Hanssen@menneske.no hvis det er ditt bilde.</>}</T></div>
                        <div><WinnerImage src={day.winner?.imageUrl}/></div>
                    </>
                    }
                    {today > day?.day && !day?.winner && <>
                        <div><T>Vinneren er ikke avgjort ennå</T></div>
                    </>}
                </WinnerInfo>
            </Info>
            <YouTubePlay day={day} width={width}/>
            <Info>
                <p>

                </p>
                <p>
                    <T>
                        Du sender inn bilder ved å laste dem opp til dagens luke. Ved å laste opp bilder tillater du at
                        bildene vises på opplastingssiden til 31. desember 2021. Dagsvinnerene vil også vises på luken
                        fra dagen etter luken ble åpnet.
                        All visning utover dette krever ditt samtykke.
                    </T>
                </p>
                <p>
                    <T>
                        Alle opplastede bilder som ikke følger tema vil bli slettet fra konkurransen. Men
                        det er lov å ha signatur på bildene sine, og det er lov å levere arkivbilde.
                    </T>
                </p>
                <p>
                    <T>
                        Denne konkurransen er ikke for å samle bilder fra folk. Den er for å motivere dere til å
                        ta flere bilder. Alle innsendte bilder vil bli slettet 1. januar 2022.
                    </T>
                </p>
                <p>
                    <T>
                        Hvis du navngir filen med navn og/eller telefonnummer så blir det enklere å få tak i deg
                        i tilfellet du vinner. Denne informasjonen vil ikke bli synlig for noen andre og vil bli
                        slettet når konkurransen er over. Hvis du ikke gjør det vil du måtte sende inn bildet
                        som bevis hvis du vinner.
                    </T>
                </p>
                <p>
                    <T>
                        Vinneren blir trukket ut fra alle deltakere på en dag. Men den siste premien på 1000 kr vil
                        blir valgt ut fra det beste bildet i løpet av adventstiden.
                    </T>
                </p>
                {today === day?.day &&
                <Button onClick={() => window.open(day.upload, "_blank")} open={true} width={"auto"}>Last opp
                    bildet</Button>
                }
                <BackButton/>
            </Info>
        </>
        }
    </Container>
}

export default ShowDay;
