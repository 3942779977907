import { device } from "../../../theme";

const { useMediaQuery } = require("react-responsive");

const useMedia = () => {
    const isMobile = useMediaQuery({ query: device.mobile });
    const isNotMobile = useMediaQuery({ query: device.t2d });
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
    const isBigScreen = useMediaQuery({ minDeviceWidth: 1824 });
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 });
    const isPortrait = useMediaQuery({ orientation: "portrait" });
    const isRetina = useMediaQuery({ minResolution: "2dppx" });

    return {
        isMobile,
        isNotMobile,
        isDesktopOrLaptop,
        isBigScreen,
        isTabletOrMobile,
        isTabletOrMobileDevice,
        isPortrait,
        isRetina,
    };
};

export default useMedia;
