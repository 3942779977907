import styled from "styled-components";
import LesserThan from "./LesserThan";
import CleanButton from "./CleanButton";
import T from "../texts/T";
import {useHistory} from "react-router-dom";

const Chevron = styled(LesserThan)`
    margin-top: 4px;
    margin-right: 5px;
    margin-left: -2px;
    width: 16px;
    height: 16px;
`;

const BackButtonStyled = styled(CleanButton)`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const BackButton = () => {
    const history = useHistory();

    return (
        <BackButtonStyled onClick={() => history.push("/")}>
            <Chevron />
            <T style={{position: "relative", top: "-3px"}}>Tilbake</T>
        </BackButtonStyled>
    );
}

export default BackButton;
