import React from "react";
import styled, { useTheme } from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: ${(props) => (props.size ? props.size : 13)}px;
    height: ${(props) => (props.size ? props.size : 13)}px;
`;

export const LesserThan = ({ className, stroke, second, size = 13 }) => {
    const theme = useTheme();
    stroke = second ? theme.color.neutral.xxxlight : stroke ? stroke : theme.color.primary.base;
    return (
        <Svg viewBox="0 0 13 18" fill="none" className={className} size={size}>
            <path d="M12 1L2 8.69231L12 17" stroke={stroke} strokeWidth="2" />
        </Svg>
    );
};

export default LesserThan;
