import styled from 'styled-components';
import H1 from "./components/texts/H1";
import {device} from "./theme";
import {days} from './data';
import T from "./components/texts/T";
import React from "react";
import BackButton from "./components/buttons/BackButton";

const Container = styled.div`
  margin: auto;
  width: 640px;
  @media only screen and ${device.mobile} {
    width: 100%;
    margin: 10px;
  }
`

const Info = styled.div`
  margin: 10px 0 10px 0;
  @media only screen and ${device.mobile} {
    margin: 10px;
  }
`

const Prices = () => {

    return <Container>
        <Info>
            <H1 style={{textAlign: "center"}}>Premier</H1>
        </Info>
        {days.map(day => <div>
            <T>Dag {day.day}: Premie: {day?.sponsor?.price} <a href={day?.sponsor?.url} target={"_blank"} rel={"noreferrer"}>Sponset
                av {day?.sponsor?.name}</a></T>

            </div>
        )}
        <T>Beste bilde: 1000 kr <a href={"https://grensesnitt.no"} target={"_blank"} rel={"noreferrer"}>Sponset av Grensesnitt</a></T>
        <BackButton/>
    </Container>
}

export default Prices;
