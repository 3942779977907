import React, {useEffect, useState} from "react";
import './App.css';
import styled from 'styled-components';
import Modal from "react-modal";
import {days} from './data';
import H1 from "./components/texts/H1";
import H2 from "./components/texts/H2";
import T from "./components/texts/T";
import {useHistory} from "react-router-dom";
import DoorButton from "./components/buttons/DoorButton";

Modal.setAppElement("#root");

const debug = false;

const Container = styled.div`
  padding-left: ${props => props.margin};
  padding-top: ${props => props.margin};
  padding-right: ${props => props.margin};
  h2 {
    margin-top: 10px;
  }
`

const DoorContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: ${props => props.margin};
  padding-top: ${props => props.margin};
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const WinnerImage = styled.img`
  margin-top: 10px;
  width: 100%;
`

const Day = ({day, today = false, ...props}) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (day) {
            const date = new Date();

            const mDay = date.getDate();
            const month = date.getMonth() + 1;
            if (month === 12 && mDay >= day.day) {
                setOpen(true);
            } else {
                setOpen(debug && day.day === 1 ? true : false);
            }

        }
    })

    return <>
        <DoorButton {...props} open={open} onClick={() => open && history.push(`/dag/${day.day}`)}>
            <T size={props.fontsize} color={open ? '#6a2132' : 'rgba(0,0,0,0.4)'}>{today ? "I dag" : day.day}</T>
        </DoorButton>
    </>
}

const Main = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const [size, setSize] = useState(70);
    const [margin, setMargin] = useState(20);
    const [fontSize, setFontSize] = useState(60);
    const [day, setDay] = useState(null);
    const history = useHistory();

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        const date = new Date();

        const mDay = date.getDate();
        const month = date.getMonth() + 1;
        if (month === 12 & mDay <= 24) {
            setDay(days.find(day => day.day === mDay));
        } else {
//            setDay(days.find(day => day.day===1));
        }
    })

    useEffect(() => {
        let minCount = 100;
        let minMargin = 100;
        let fSize = 0;
        let sMargin = 20;

        for (let size = 50; size <= 300; size++) {
            const w = Math.floor((width - sMargin) / (size + sMargin));
            const newMargin = Math.floor((width - (w * size)) / (w + 1));
            const h = Math.floor((height - newMargin) / (size + newMargin));

            if (w * h >= 24) {
                if (w * h < minCount) {
                    minCount = w * h;
                    fSize = size;
                    minMargin = newMargin;
                }
                if (w * h === minCount) {
                    if (newMargin < minMargin) {
                        minMargin = newMargin;
                        fSize = size;
                    }
                }
            }
        }
        setSize(fSize);
        setMargin(minMargin);
        setFontSize(Math.min(fSize - 20, 60));
    }, [width, height])

    return (
        <Container margin={margin + "px"}>
            <H1>Adventskalender for fotoinspirerte</H1>
            {1 == 0 && <><a href={"https://photos.flogvit.com/Adventskalender/2021"} target={"_blank"} rel={"noreferrer"}>
                <T>Se alle innsendte bilder</T>
            </a>
                <H2 onClick={() => history.push("/premier")} style={{cursor: "pointer"}}>Delta og vinn daglige
                premier!</H2>
                <DoorContainer>
            {days.map(day => {
                return <Day key={day.day} day={day} size={size + "px"} margin={margin + "px"}
                fontsize={fontSize + "px"}/>
            })}
                </DoorContainer>
            </>}
            <H2>Vinnerbildet 2021</H2>
            <WinnerImage src={'/assets/vinner.jpg'} margin={margin + "px"}/>
            <T>Adventskalenderen starter opp igjen 1 desember 2022, så kom tilbake da.</T>
            <p>
            <T>Sponsorer 2021: <a href={"https://grensesnitt.no"} target={"_blank"} rel={"noreferrer"}>Grensesnitt</a> <a href={"https://menneske.no"} target={"_blank"} rel={"noreferrer"}>Flogvit Data</a></T>
            </p>
            <Logo>
                <a href={"https://facebook.com/fotospiren"} target={"_blank"}
                   rel={"noreferrer"}><T>Fotospirene</T></a>
            </Logo>
        </Container>
    );
}

export default Main;
