import styled from "styled-components";
import Text from "./Text";

export const T = styled(Text)`
  font-family: ${(props) => props.theme.font.t["font-family"]};
  font-style: ${(props) => props.theme.font.t["font-style"]};
  font-weight: ${(props) => props.theme.font.t["font-weight"]};
  font-size: ${(props) => props?.size ? props.size : props.theme.font.t["font-size"]};
  line-height: ${(props) => props.theme.font.t["line-height"]};
  color: ${(props) => props.color ? props.color : "#6a2132"};
  text-shadow: -0.2px 0 white, 0 0.2px white, 0.2px 0 white, 0 -0.2px white;
`;

export default T;
