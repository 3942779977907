import styled from "styled-components";

export const Button = styled.div`
  width: ${props => props.width};
  position: relative;
  min-height: 40px;
  background-image: url("/border.svg");
  border-radius: 9px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica Neue', sans-serif;
  cursor: ${props => props.open ? 'pointer' : 'default'};
  color: ${props => props.open ? '#6a2132' : 'rgba(0,0,0,0.4)'};

  .text {
    padding: 5px 5px 5px 5px;
  }

`
