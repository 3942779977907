import styled from "styled-components";
// import React from 'react'

export const H1 = styled.h1`
    margin-block-start: 0;
    font-family: ${(props) => props.theme.font.h1["font-family"]};
    font-style: ${(props) => props.theme.font.h1["font-style"]};
    font-weight: ${(props) => props.theme.font.h1["font-weight"]};
    font-size: ${(props) => props.theme.font.h1["font-size"]};
    line-height: ${(props) => props.theme.font.h1["line-height"]};
    text-transform: ${(props) => props.theme.font.h1["text-transform"]};
    color: ${(props) =>
        props.color
            ? props.color
            : props.theme.text.color.main};
  letter-spacing: -1px;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  margin-bottom: 5px;
`;

export default H1;
