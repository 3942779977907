import styled from "styled-components";

export const Text = styled.span`
  color: ${(props) =>
          props.color
                  ? props.color
                  : props.theme.text.color.main};
  border-bottom: ${(props) => (props.underline ? "2px solid currentColor" : "none")};
  cursor: ${(props) => (props.link ? "pointer" : undefined)};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

`;

export default Text;
